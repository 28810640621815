import React, { useState } from "react";
import { Modal } from "antd";
import Classes from "./profilemodal.module.scss";
import User from "../../assets/images/icons/userYellow.svg";
import Edit from "../../assets/images/icons/edit.svg";
import ProfileImg from "../../assets/images/section/userImg.svg";
import Profile from "../../components/Profile";
import ProfileCard from "../../components/ProfileCard";

function ProfileModal({ isModalOpen, handleCancel }) {
  const [profileImage, setProfileImage] = useState(ProfileImg);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Modal
      className={Classes.profileModal}
      footer={null}
      open={isModalOpen}

      // onCancel={handleCancel}
    >
      <ProfileCard isModal={true}/>
    </Modal>
  );
}

export default ProfileModal;
