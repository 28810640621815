import React, { useEffect, useState } from "react";
import Classes from "./sidebar.module.scss";
import { Menu } from "antd";
import Logo from "../../../assets/images/section/logo.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/images/icons/dashboard.svg";
import { ReactComponent as Protocol } from "../../../assets/images/icons/protocol.svg";
import { ReactComponent as Investment } from "../../../assets/images/icons/investment.svg";
import { ReactComponent as Polls } from "../../../assets/images/icons/investment.svg";

import { ReactComponent as User } from "../../../assets/images/icons/user.svg";
import { Link } from "react-router-dom";

const SidebarComponent = ({ collapsed }) => {
  const [showName, setShowName] = useState(false);

  useEffect(() => {
    let timeoutId;
    // If the sidebar is expanded, set a timeout to show the name after a delay
    if (!collapsed) {
      timeoutId = setTimeout(() => {
        setShowName(true);
      }, 100);
    } else {
      // If the sidebar is collapsed, immediately hide the name
      setShowName(false);
    }
    // Cleanup function to clear the timeout when the component unmounts or when the collapsed state changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [collapsed]);

  const menuItems = [
    {
      key: "1",
      icon: <DashboardIcon />,
      label: <Link to="/dashboard">Main Dashboard</Link>,
      title: null,
    },
    {
      key: "2",
      icon: <DashboardIcon />,
      label: <Link to="/your-dashboard">Your Dashboard</Link>,
      title: null,
    },
    {
      key: "3",
      icon: <Protocol />,
      label: <Link to="/protocol-metrics">Protocol Metrics</Link>,
      title: null,
    },
    {
      key: "4",
      icon: <User />,
      label: <Link to="/profile">Profile</Link>,
      title: null,
    },
    {
      key: "5",
      icon: <Protocol />,
      label: <Link to="/withdrawal">Withdrawal</Link>,
      title: null,
    },
    // {
    //   key: "5",
    //   icon: <Polls />,
    //   label: <Link to="/payout">Payout</Link>,
    // },
  ];

  return (
    <aside className={Classes.sidebar}>
      <Link
        to="/"
        className={`${Classes.logo} d-flex align-items-center gap-1 pb-3 px-3`}
      >
        <img src={Logo} alt="logo" />
        {showName && <p className={Classes.name}>The Warriors DAO</p>}
      </Link>
      <Menu defaultSelectedKeys={["1"]} className="px-3" items={menuItems} />
    </aside>
  );
};

export default SidebarComponent;
