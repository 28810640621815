import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Custom animation function

// (function (H) {
//   H.seriesTypes.pie.prototype.animate = function (init) {
//     console.log('called')
//     const series = this,
//       chart = series.chart,
//       points = series.points,
//       { animation } = series.options,
//       { startAngleRad } = series;

//     function fanAnimate(point, startAngleRad) {
//       const graphic = point.graphic,
//         args = point.shapeArgs;

//       if (graphic && args) {
//         graphic
//           .attr({
//             start: startAngleRad,
//             end: startAngleRad,
//             opacity: 1,
//           })
//           .animate(
//             {
//               start: args.start,
//               end: args.end,
//             },
//             {
//               duration: animation.duration / points.length,
//             },
//             function () {
//               if (points[point.index + 1]) {
//                 fanAnimate(points[point.index + 1], args.end);
//               }
//               if (point.index === series.points.length - 1) {
//                 series.dataLabelsGroup.animate(
//                   {
//                     opacity: 1,
//                   },
//                   void 0,
//                   function () {
//                     points.forEach((point) => {
//                       point.opacity = 1;
//                     });
//                     series.update(
//                       {
//                         enableMouseTracking: true,
//                       },
//                       false
//                     );
//                     chart.update({
//                       plotOptions: {
//                         pie: {
//                           innerSize: "70%",
//                           borderRadius: 0,
//                         },
//                       },
//                     });
//                   }
//                 );
//               }
//             }
//           );
//       }
//     }

//     if (init) {
//       points.forEach((point) => {
//         point.opacity = 0;
//       });
//     } else {
//       fanAnimate(points[0], startAngleRad);
//     }
//   };
// })(Highcharts);

const HighchartsPieChart = ({data}) => {
  // useEffect(()=>{
  //   (function (H) {
  //     H.seriesTypes.pie.prototype.animate = function (init) {
  //       console.log('called')
  //       const series = this,
  //         chart = series.chart,
  //         points = series.points,
  //         { animation } = series.options,
  //         { startAngleRad } = series;
    
  //       function fanAnimate(point, startAngleRad) {
  //         const graphic = point.graphic,
  //           args = point.shapeArgs;
    
  //         if (graphic && args) {
  //           graphic
  //             .attr({
  //               start: startAngleRad,
  //               end: startAngleRad,
  //               opacity: 1,
  //             })
  //             .animate(
  //               {
  //                 start: args.start,
  //                 end: args.end,
  //               },
  //               {
  //                 duration: animation.duration / points.length,
  //               },
  //               function () {
  //                 if (points[point.index + 1]) {
  //                   fanAnimate(points[point.index + 1], args.end);
  //                 }
  //                 if (point.index === series.points.length - 1) {
  //                   series.dataLabelsGroup.animate(
  //                     {
  //                       opacity: 1,
  //                     },
  //                     void 0,
  //                     function () {
  //                       points.forEach((point) => {
  //                         point.opacity = 1;
  //                       });
  //                       series.update(
  //                         {
  //                           enableMouseTracking: true,
  //                         },
  //                         false
  //                       );
  //                       chart.update({
  //                         plotOptions: {
  //                           pie: {
  //                             innerSize: "70%",
  //                             borderRadius: 0,
  //                           },
  //                         },
  //                       });
  //                     }
  //                   );
  //                 }
  //               }
  //             );
  //         }
  //       }
    
  //       if (init) {
  //         points.forEach((point) => {
  //           point.opacity = 0;
  //         });
  //       } else {
  //         fanAnimate(points[0], startAngleRad);
  //       }
  //     };
  //   })(Highcharts);
  // })
  const options = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      height: 320
    },
    title: {
      text: "",
      align: "left",
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    subtitle: {
      text: "",
      align: "left",
    },
    tooltip: {
      pointFormat: "<b>{point.y}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 0,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          distance: -10, // Adjust distance for better visibility
          useHTML: true,
          formatter: function () {
            return `<div class='d-flex justify-content-center align-items-center flex-column tooltipOuter'>
                    <span class='tooltipTxt'>${this.point.y}%</span>
                    <span class='tooltipName'><b>${this.point.name}</b></span>
                </div>`;
          },
        },
      },
    },
    series: [
      {
        enableMouseTracking: false,
        animation: {
          duration: 2000,
        },
        colorByPoint: true,
        data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};


export default HighchartsPieChart;

// import React from "react";
// import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
// import CustomTooltip from "../CustomTooltip";

// const data = [
//   { name: "Liquid Warriors", value: 65, color: "#efb323" },
//   { name: "Warrior Rush", value: 35, color: "#7e7e8b" },
//   { name: "Drip Marble", value: 23, color: "#FF000A" },
//   { name: "Plinko Fortune", value: 12, color: "#a5a6b4" },
// ];

// const RADIAN = Math.PI / 180;

// const renderCustomTooltip = (cx, cy, midAngle, outerRadius, name, value) => {
//   const radius = outerRadius * 0.7;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <foreignObject
//       x={x}
//       y={y}
//       width={100}
//       height={100}
//       style={{ pointerEvents: "none", position: "absolute", }}
//     >
//       <CustomTooltip active={true} payload={[{ name, value }]} />
//     </foreignObject>
//   );
// };

// function Piechart() {
//   const cx = 200;
//   const cy = 200;
//   const outerRadius = 150;

//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <PieChart>
//         <defs>
//           <filter id="shadow" x="-40%" y="-40%" width="170%" height="170%">
//             <feDropShadow
//               dx="0"
//               dy="0"
//               stdDeviation="5"
//               floodColor="#000"
//               floodOpacity="0.5"
//             />
//           </filter>
//         </defs>
//         <Pie
//           data={data}
//           cx={cx}
//           cy={cy}
//           innerRadius={100}
//           outerRadius={outerRadius}
//           fill="#8884d8"
//           dataKey="value"
//           strokeWidth={0}
//         >
//           {data.map((entry, index) => (
//             <Cell
//               key={`cell-${index}`}
//               fill={entry.color}
//               style={{
//                 filter:
//                   entry.name === "OwnerShip" || entry.name === "Drip Marble"
//                     ? "url(#shadow)"
//                     : "none",
//                 opacity:
//                   entry.name === "OwnerShip" || entry.name === "Drip Marble"
//                     ? 1
//                     : 4,
//               }}
//             />
//           ))}
//         </Pie>
//         {data.map((entry, index) => {
//           const midAngle =
//             ((entry.value / data.reduce((acc, cur) => acc + cur.value, 0)) *
//               360) /
//             2;
//           return renderCustomTooltip(
//             cx,
//             cy,
//             midAngle + (index * 360) / data.length,
//             outerRadius,
//             entry.name,
//             entry.value
//           );
//         })}
//       </PieChart>
//     </ResponsiveContainer>
//   );
// }

// export default Piechart;
