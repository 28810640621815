import React from "react";
import Classes from "./footer.module.scss";
import { Col, Row } from "antd";
import Logo from "../../../assets/images/section/logo.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className={`${Classes.footer} px-4`}>
      <Row>
        <Col lg={8} md={24} sm={24} xs={24}>
          <Link to="/" className="d-flex align-items-center gap-2 mb-3">
            <img height={60} src={Logo} alt="logo" />
            <span className={Classes.name}>The Warriors DAO</span>
          </Link>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24}>
          <ul>
            <li>
              <Link className={Classes.link} to="/">
                Privary Policy
              </Link>
            </li>
            <li>
              <Link className={Classes.link} to="/">
                Terms of Service
              </Link>
            </li>
            <li>
              <Link className={Classes.link} to="/">
                Documentation
              </Link>
            </li>
          </ul>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24}>
          <ul>
            <li>
              <Link className={Classes.link} to="/">
                Marketplace
              </Link>
            </li>
            <li>
              <Link className={Classes.link} to="/">
                Contact
              </Link>
            </li>
            <li>
              <Link className={Classes.link} to="/">
                How it works
              </Link>
            </li>

            <li>
              <Link className={Classes.link} to="/">
                Blog
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
