import React, { useState, useEffect } from "react";
import Classes from "./profilecard.module.scss";
import Edit from "../../assets/images/icons/edit.svg";
import ProfileImg from "../../assets/images/section/userImg.svg";
import { fetchUser, updateUser } from "../../api/services/user";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setUserData,
  setUserProfileModalStatus,
} from "../../store/_actions/auth_action";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { color } from "highcharts";
function ProfileCard({ isModal }) {
  const [profileImage, setProfileImage] = useState(ProfileImg);
  const [file, setFile] = useState(null);
  const [user, setUser] = useState({
    name: "",
    email: "",
  });
  const [loader, setLoader] = useState(false);
  const userInfo = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo !== null) {
      const { name, email,emergencyEmail,emergencyContact, profileImage } = userInfo;
      setUser({ name: name || "", email: email || "",emergencyEmail:emergencyEmail||'',emergencyContact:emergencyContact||'' });
      setProfileImage(profileImage ? profileImage : ProfileImg);
    }
  }, [userInfo]);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      const formData = new FormData();
      for (let key in values) {
        if (values[key]) {
          formData.append(key, values[key]);
        }
      }
      if (file) {
        formData.append("profileImage", file);
      }
      const res = await updateUser(formData);
      if (res.status === 200) {
        const res = await fetchUser();
        dispatch(setUserData(res.data));
        dispatch(setUserProfileModalStatus(false));
        setLoader(false);
        toast.success("Profile updated successfully");
      }
    } catch (err) {
      setLoader(false);
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(15, "Name cannot exceed 15 characters")
      .required("Name is required"),
    email: Yup.string().email("Invalid email format"),
    emergencyEmail: Yup.string().email("Invalid emergencyEmail format"),

    emergencyContact: Yup.string()
  .matches(/^[0-9]{10,15}$/, "Emergency Contact must be between 10 and 15 digits and contain only numbers")
  .required("Contact number is required"),

    // .required('Email is required'),s
  });

  return (
    <div className={Classes.profileCard}>
      <div className={Classes.card}>
        <div className="mb-5">
          {isModal && (
            <p className="text-center mb-2" style={{ color: "red" }}>
              Please fill out at least the required fields to continue
            </p>
          )}

          <div className={`${Classes.label} mb-4`}>Profile Image</div>
          <div className={Classes.profileOuter}>
            {profileImage && (
              <img
                src={profileImage}
                alt="Avatar"
                className={Classes.profileImg}
                name="profileImage"
              />
            )}
            <div className={Classes.middle}>
              <img
                className={Classes.edit}
                height="30px"
                width="30px"
                src={Edit}
                alt="edit"
              />
              <input
                className={Classes.uploader}
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            name: userInfo?.name,
            email: userInfo?.email,
            emergencyEmail: userInfo.emergencyEmail,
            emergencyContact: userInfo.emergencyContact,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleChange }) => (
            <Form>
              <div className="mb-4">
                <div className={`${Classes.label} mb-1`}>User Name*</div>
                <Field
                  type="text"
                  className="baseInput d-block"
                  placeholder="Enter User name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  style={{ color: "red" }}
                  className="error mt-2"
                />
              </div>
              <div className="mb-4">
                <div className={`${Classes.label} mb-1`}>E-mail</div>
                <Field
                  type="email"
                  className="baseInput d-block"
                  placeholder="Enter your e-mail"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "red" }}
                  className="error mt-2"
                />
              </div>

              <div className="mb-4">
                <div className={`${Classes.label} mb-1`}>Emergency E-mail</div>
                <Field
                  type="email"
                  className="baseInput d-block"
                  placeholder="Enter your emergency e-mail"
                  name="emergencyEmail"
                  value={values.emergencyEmail}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="emergencyEmail"
                  component="div"
                  style={{ color: "red" }}
                  className="error mt-2"
                />
              </div>
              <div className="mb-4">
                <div className={`${Classes.label} mb-1`}>Emergency Contact</div>
                <Field
                  type="text"
                  className="baseInput d-block"
                  placeholder="Enter your emergency contact"
                  name="emergencyContact"
                  value={values.emergencyContact}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="emergencyContact"
                  component="div"
                  style={{ color: "red" }}
                  className="error mt-2"
                />
              </div>
              <button
                type="submit"
                className="btn"
                disabled={isSubmitting || loader}
              >
                {loader ? "loading" : "save"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ProfileCard;
